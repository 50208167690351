import React, { useState } from 'react';
import axios from 'axios';

import '../Style/pages.css'
const Pages = () => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const enginify = "4ae937b1-a0de-49a7-9ea4-e5f2ed7fa3ee";

  const submitSaas = async (e) => {
      e.preventDefault();
      try {
          const response = await axios.post("https://emailoctopus.com/api/1.6/lists/1c7f30e0-39c9-11ef-ad6e-a73407ee54fa/contacts", {
              "api_key": enginify,
              "email_address": email,
              "fields": {
                  "FirstName": name
              },
              "tags": [
                  "vip"
              ],
              "status": "SUBSCRIBED"
          },{headers: {
            'Content-Type': 'application/json',
          }});          
          alert('Request sent successfully: ' + response.data);
          window.location.reload(); // Refresh the page
      } catch (error) {
          console.error('There was an error!', error);
          alert('Error sending request: ' + error.message);
          window.location.reload();
      }
    };


  return (
    <div>
      <div className='prodect-area'>
        <div className='prodect-image'>
          <img src='https://ik.imagekit.io/qqdkfqp7h/Untitled.png?updatedAt=1720120493084' />
        </div>
        <div className='prodect-detail'>
          <h1>
            Infopass
          </h1>
          <p>
          Infopass is an innovative KYC compliance platform offering comprehensive Know-Your-Customer (KYC), and digital identity services for secure client verification, screening, and lifecycle management.
          
          </p>
          <a href='https://infopass.in/'>
            <button className='btn-sub'>
              Visit Infopass<img src='https://ik.imagekit.io/qqdkfqp7h/Screenshot_2024-07-05_124308-removebg-preview.png?updatedAt=1720163838160'
                             alt="Icon"
                             style={{ width: '35px', height: '20px',marginLeft:'2px',marginBottom:'-3px' }}/>
            </button>
          </a>

        </div>

      </div>
      <div className='prodect-contact'>
        <div className='page-he'>
          <h2 class="title-text heading  text-white">Stay Ahead in SaaS</h2>
        </div>
        <div className='page-st'>
          <p>Sign up for our monthly emails and stay updated with the latest additions to the Best SaaS Projects directory. No spam, just fresh SaaS updates.</p>
        </div>
        <div >
          <form className='page-form' onSubmit={submitSaas}>
            <div className='Supercharge-fname'>
              <label>
                Your Name
              </label>
              <input type='text' name='name' id='name' value={name} onChange={e => setName(e.target.value)} required placeholder='Your Name' />
            </div>
            <div className='Supercharge-fname'>
              <label>
                Your Email
              </label>
              <input type='email' name='email' id='email' value={email} onChange={e => setEmail(e.target.value)} required placeholder='Your@email.com' />
            </div>
            <div className='Supercharge-btn'>
              <button>Get Saas Smart
              <img src='https://ik.imagekit.io/qqdkfqp7h/Screenshot_2024-07-05_124308-removebg-preview.png?updatedAt=1720163838160'
                             alt="Icon"
                             style={{ width: '35px', height: '20px',marginLeft:'2px',marginBottom:'-3px' }}/>
              </button>
            </div>

          </form>

        </div>
      </div>
    </div>
  );
}

export default Pages;
