import React, { useState } from 'react';
import axios from 'axios';

const Easymsg = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const enginify = "4ae937b1-a0de-49a7-9ea4-e5f2ed7fa3ee";

    const submitSaas = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post("https://emailoctopus.com/api/1.6/lists/1c7f30e0-39c9-11ef-ad6e-a73407ee54fa/contacts", {
                "api_key": enginify,
                "email_address": email,
                "fields": {
                    "FirstName": name
                },
                "tags": [
                    "vip"
                ],
                "status": "SUBSCRIBED"
            });
            alert('Request sent successfully: ');
            window.location.reload(); // Refresh the page
        } catch (error) {
            console.error('There was an error!', error);
            alert('Error sending request: ' + error.message);
            window.location.reload();
        }
    };

    return (
        <div>
            <div className='prodect-area'>
                <div className='prodect-image'>
                    <img src='https://ik.imagekit.io/qqdkfqp7h/Screenshot_4-7-2024_201833_eazymsg.com.jpeg?updatedAt=1720104577347' />
                </div>
                <div className='prodect-detail'>
                    <h1>
                        Eazy MSG
                    </h1>
                    <p>
                    Eazymsg is utilize various communication channels such as SMS, email, AI bots, push notifications, and WhatsApp API, that cover the full spectrum of compliance challenges, from new client onboarding to a comprehensive database for ongoing client monitoring.
                    </p>
                    <a href='https://enginify.in/'>
                        <button className='btn-sub'>
                            Visit EazyMSG <img src='https://ik.imagekit.io/qqdkfqp7h/Screenshot_2024-07-05_124308-removebg-preview.png?updatedAt=1720163838160'
                             alt="Icon"
                             style={{ width: '35px', height: '20px',marginLeft:'2px',marginBottom:'-3px' }}/>
                        </button>
                    </a>

                </div>

            </div>
            <div className='prodect-contact'>
                <div className='page-he'>
                    <h2 class="title-text heading  text-white">Stay Ahead in SaaS</h2>
                </div>
                <div className='page-st'>
                    <p>Sign up for our monthly emails and stay updated with the latest additions to the Best SaaS Projects directory. No spam, just fresh SaaS updates.</p>
                </div>
                <div style={{marginTop:"-76px"}}>
                    <form className='page-form' onSubmit={submitSaas}>
                        <div className='Supercharge-fname'>
                            <label>
                                Your Name
                            </label>
                            <input type='text' name='name' id='name' value={name} onChange={e => setName(e.target.value)} required placeholder='Your Name' />
                        </div>
                        <div className='Supercharge-fname'>
                            <label>
                                Your Email
                            </label>
                            <input type='email' name='email' id='email' value={email} onChange={e => setEmail(e.target.value)} required placeholder='Your@email.com' />
                        </div>
                        <div className='Supercharge-btn'>
                            <button>Get Saas Smart
                            <img src='https://ik.imagekit.io/qqdkfqp7h/Screenshot_2024-07-05_124308-removebg-preview.png?updatedAt=1720163838160'
                             alt="Icon"
                             style={{ width: '35px', height: '20px',marginLeft:'2px',marginBottom:'-3px' }}/>
                            </button>
                        </div>

                    </form>

                </div>
            </div>
        </div>
    );
}

export default Easymsg;
