import React from 'react';
import '../../Style/navbar.css'
  
  const Navbar = () =>  {
	return (
	  <nav>
		<div class="container1">
			<div class="nav-02__box">
				<div class="nav-02__logo"><a class="nav-02__link" href="/" target="_self">
				<img class="nav-02__logo_img" height="35" alt="enginify logo" src="https://ik.imagekit.io/neuwx10vxnmeo/enginify/images/e_logo_white_NESIvSD8Ko.png?updatedAt=1718015717368"/>
				</a>
                </div>
			    {/* <div class="nav-02__links js-menu">
                    <button >Submit SAAS</button>
                </div> */}
            
            </div>
        </div>
	
      </nav>
	);
  }
  
  export default Navbar;
  