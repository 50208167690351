import React, { useState } from 'react';
import axios from 'axios';
import '../../Style/home.css'
import Button from 'react-bootstrap/Button';
import Card from '../../Card/Card';


const Home = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const enginify = "4ae937b1-a0de-49a7-9ea4-e5f2ed7fa3ee";

    const submitSaas = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post("https://emailoctopus.com/api/1.6/lists/1c7f30e0-39c9-11ef-ad6e-a73407ee54fa/contacts", {
                "api_key": "",
                "email_address": email,
                "fields": {
                    "FirstName": name
                },
                "tags": [
                    "vip"
                ],
                "status": "SUBSCRIBED"
            });
            alert('Request sent successfully: ');
            window.location.reload(); // Refresh the page
        } catch (error) {
            console.error('There was an error!', error);
            alert('Error sending request: ' + error.message);
            window.location.reload();
        }
    };
    return (
        <div>
            <div class="page-component__wrapper">
                <header class="header-29">
                    <div class="header-29__box">
                        <div class="container container--mid header-29__container">
                            <div class="header-29__text_box">
                                <h1 class="title-text heading heading--accent header-29__heading  text-white">Explore &amp; Amplify Leading SaaS Innovations</h1>
                                <div class="subtitle-text header-29__text content_box  text-white"><h3 style={{ color: 'white', fontSize: '20px' }}>Your Path to Top-tier SaaS Projects Begins Here!&nbsp;</h3>
                                </div>
                                <div class="header-29__buttons">
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </div>

            <Card />



            <div className='saas-contaner'>
                <div className='saas-header'>
                    <h1 style={{ fontSize: '45px' }}>SaaS Innovation Unveiled</h1>
                    <p style={{ fontSize: '20px', margin: '-18px 0 31px 0' }}>Stay Ahead with the Latest SaaS Trends!</p>
                    <div className='saas-body'>
                        <div className='saas-card'>
                            <img src='https://unicorn-cdn.b-cdn.net/9b6a704a-b68f-44c1-89f3-5d1a1f39f0ba/denys-nevozhai-63znf38gnxk-unsplash-(1).png' />
                            <div className='saas-card-heading'>
                                <div className='saas-h1'>
                                    {/* <h1></h1><p></p> */}
                                    <h1>
                                        <span className="large-text">Revolutionizing SaaS with Project Alpha</span>
                                        <span className="small-text"> 5 min read</span>
                                    </h1>
                                </div>
                                <div>
                                    <p style={{ fontSize: '18px', margin: '-3px 0 31px 0' }}>Discover how Project Alpha is changing the SaaS landscape with innovative solutions.</p>
                                </div>

                            </div>

                            <div class="feature__button_box">
                                <a data-stripe-product-id="" data-stripe-mode="payment" data-successful-payment-url="" data-cancel-payment-url="" class="button button--empty  button--accent-outline" href="https://enginify.in/blog" target="_blank">
                                    <span class="button__text">Read post</span>
                                    <img
                                        src="https://ik.imagekit.io/qqdkfqp7h/ddd1c2476bcb099d675bf282f0a40192-removebg-preview.png?updatedAt=1720163233846"
                                        alt="Icon"
                                        style={{ width: '35px', height: '20px',marginLeft:'14px' }}
                                    />
                                </a>
                            </div>
                        </div>
                        <div className='saas-card'>
                            <img src='https://unicorn-cdn.b-cdn.net/f222de79-dba3-4daf-ba05-c1ad9bd6f473/casey-horner-g2jaomgglpe-unsplash-(1).png' />
                            <div className='saas-card-heading'>
                                <div className='saas-h1'>
                                    <h1>
                                        <span className="large-text">The Power of SaaS Solutions</span>
                                        <span className="small-text"> 8 min read</span>
                                    </h1>
                                </div>
                                <div>
                                    <p style={{ fontSize: '18px', margin: '-3px 0 31px 0' }}>Learn about the benefits of using SaaS solutions for your business.</p>
                                </div>

                            </div>
                            <div class="feature__button_box">
                                <a data-stripe-product-id="" data-stripe-mode="payment" data-successful-payment-url="" data-cancel-payment-url="" class="button button--empty  button--accent-outline" href="https://enginify.in/blog" target="_blank">
                                    <span class="button__text">Read post</span>
                                    <img
                                        src="https://ik.imagekit.io/qqdkfqp7h/ddd1c2476bcb099d675bf282f0a40192-removebg-preview.png?updatedAt=1720163233846"
                                        alt="Icon"
                                        style={{ width: '35px', height: '20px',marginLeft:'14px' }}
                                    />
                                </a>
                            </div>
                        </div>
                        <div className='saas-card'>
                            <img src='https://unicorn-cdn.b-cdn.net/ca7ce9b4-d4da-4b58-942d-d1b92bfddffe/jonathan-forage-1azajl8ftnu-unsplash-(1).png' />
                            <div className='saas-card-heading'>
                                <div className='saas-h1'>
                                    <h1>
                                        <span className="large-text">Why Choose Project Beta</span>
                                        <span className="small-text"> 10 min read</span>
                                    </h1>
                                </div>
                                <div>
                                    <p style={{ fontSize: '18px', margin: '-3px 0 31px 0' }}>Find out why businesses are choosing Project Beta for their SaaS needs.</p>
                                </div>

                            </div>
                            <div class="feature__button_box">
                                <a data-stripe-product-id="" data-stripe-mode="payment" data-successful-payment-url="" data-cancel-payment-url="" class="button button--empty  button--accent-outline" href="https://enginify.in/blog" target="_blank">
                                    <span class="button__text">Read post</span>
                                    <img
                                        src="https://ik.imagekit.io/qqdkfqp7h/ddd1c2476bcb099d675bf282f0a40192-removebg-preview.png?updatedAt=1720163233846"
                                        alt="Icon"
                                        style={{ width: '35px', height: '20px',marginLeft:'14px' }}
                                    />
                                </a>
                            </div>
                        </div>

                    </div>
                </div>

            </div>





            <div className='home-join'>
                <div className='join-contaner'>
                    <div className='join-heading'>
                        <h1 style={{ fontSize: '45px' }}>Our Partners</h1>

                        <p style={{ fontSize: '20px', margin: '-18px 0 31px 0' }}> We are proud to work with these industry leaders.</p>
                    </div>
                    <div className='company-img'>
                        <div className='company-img1'>
                           <a href="https://enginify.in/"><img src='https://ik.imagekit.io/neuwx10vxnmeo/enginify/images/e_logo_white2_oX6QrrYOR.png?updatedAt=1718015720183' /></a> 
                           <a href="https://infopass.in/"><img src='https://unicorn-cdn.b-cdn.net/bc7dfc23-9f01-4853-94f8-92f29f80d08e/infopass-logo.png?width=258&height=37' /></a> 
                            <img src='https://unicorn-cdn.b-cdn.net/e9b8c04d-5541-4526-b4ba-2f5ba870aa54/allgpts-light-logo-(1).png?width=146&height=32' />
                        </div>
                        <div className='company-img2'>
                            <img src='https://unicorn-cdn.b-cdn.net/2ede3218-0b52-430f-9366-31d9a63d4ff2/listingbott-light-logo-(1).png?width=94&height=42' />
                            <img src='https://unicorn-cdn.b-cdn.net/fe452c49-1f56-40cd-a11f-a4647899e101/cofondr-light-logo-(1).png?width=164&height=37' />
                            <img src='https://unicorn-cdn.b-cdn.net/7f776339-6612-4248-bdff-6e1672a49db4/index-rusher-light-logo-(1).png?width=234&height=40 ' />
                        </div>
                    </div>
                </div>

            </div>

            <div className='Supercharge'>
                <div className='Supercharge-cotaner'>
                    <div className='Supercharge-heading'>
                        <h1 style={{ fontSize: '45px' }}>Supercharge Your SaaS!</h1>

                        <p style={{ fontSize: '20px', margin: '-18px 0 31px 0' }}>Subscribe for timely updates on our curated Best SaaS Projects</p>
                    </div>
                    <form className='Supercharge-form' onSubmit={submitSaas}>
                        <div className='Supercharge-fname'>
                            <label>
                                Your Name
                            </label>
                            <input type='text' name='name' id='name' value={name} onChange={e => setName(e.target.value)} required placeholder='Your Name' />
                        </div>
                        <div className='Supercharge-fname'>
                            <label>
                                Your Email
                            </label>
                            <input type='email' name='email' id='email' value={email} onChange={e => setEmail(e.target.value)} required placeholder='Your@email.com' />
                        </div>
                        <div className='Supercharge-btn'>
                            <button>Get Saas Smart <img src='https://ik.imagekit.io/qqdkfqp7h/Screenshot_2024-07-05_124308-removebg-preview.png?updatedAt=1720163838160'
                             alt="Icon"
                             style={{ width: '35px', height: '20px',marginLeft:'2px',marginBottom:'-3px' }}/></button>
                        </div>

                    </form>

                </div>

            </div>
        </div>
    );
}

export default Home;
