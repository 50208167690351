import React from 'react';
import '../Style/card.css'
  const Card = () =>  {
	return (
	  <div className='card-contaner' >
        <div className='card1'>
        <a  href='/Easymsg' target="_blank" rel="noopener noreferrer" >
        <div className='card-bo'>
          <img src='https://ik.imagekit.io/qqdkfqp7h/eazypee.png?updatedAt=1720164728337'/>
            <h1 className='card-header'>
                  EazyMSG
            </h1>
            <div className='star-img'>
              160
            <img src='https://ik.imagekit.io/qqdkfqp7h/images__2_-removebg-preview.png?updatedAt=1720160187541'/>
            </div>
            </div>
            <p className='card-body'>Eazymsg utilizes SMS, email, AI bots, push notifications, and WhatsApp API to streamline client onboarding and maintain continuous compliance monitoring.</p>

        </a>
        </div>
        <div className='card1'>
        <a  href='/pages' target="_blank" rel="noopener noreferrer">
        <div className='card-bo'>
          <img src='https://ik.imagekit.io/qqdkfqp7h/infopass.png?updatedAt=1720164603120'/>
           
            <h1 className='card-header'>
                  Infopass
            </h1>
            <div className='star-img'>
              159
            <img src='https://ik.imagekit.io/qqdkfqp7h/images__2_-removebg-preview.png?updatedAt=1720160187541'/>
            </div>
            </div>

            <p className='card-body'>Infopass simplifies KYC and KYB processes with advanced AI tools, ensuring seamless client verification and continuous compliance monitoring.</p>

        </a>
        </div>
        <div className='card1'>
        <a  href='/Easype' target="_blank" rel="noopener noreferrer">
        <div className='card-bo'>
          <img src='https://ik.imagekit.io/qqdkfqp7h/eazypee.png?updatedAt=1720164728337'/>
           
            <h1 className='card-header'>
                  EazyPE
            </h1>
            <div className='star-img'>
              173
            <img src='https://ik.imagekit.io/qqdkfqp7h/images__2_-removebg-preview.png?updatedAt=1720160187541'/>
            </div>
            </div>
            <p className='card-body'>First Mobile & DTH Recharge API made for developers. Enable your apps and websites to send mobile recharge worldwide.</p>

        </a>
        </div>
	  </div>
	);
  }
  
  export default Card;
  