import {BrowserRouter, Routes, Route, Link} from 'react-router-dom';
import Navbar from './Component/Pages/app/Navbar';
import Footer from './Component/Pages/app/Footer';
import Home from './Component/Pages/app/Home';
import Pages from './Component/Card-Page/pages';
import Easymsg from './Component/Card-Page/easymsg';
import Easype from './Component/Card-Page/easype';
import './../node_modules/bootstrap/dist/css/bootstrap-grid.min.css'



function App() {
  return (
    <div className="App">
     <BrowserRouter>
    <header className="App-header">
      </header>
      <Navbar/>
      <Routes>
      < Route path="/" element={<Home/>} />
      < Route path="/pages" element={<Pages/>} />
      < Route path="/Easymsg" element={<Easymsg/>} />
      < Route path="/Easype" element={<Easype/>} />

      
      </Routes>
      
      <Footer/>
     </BrowserRouter> 
     
    </div>
  );
}

export default App;