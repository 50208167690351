import React from 'react';
import '../../Style/footer.css'

const Footer = () => {
    return (

        <footer class="container-footer">
            <div class="footer-02__wrapper">
                <div class="content-text">
                <p>© 2024&nbsp;Made with ❤️ by 
                        <a href="https://enginify.in/" target="_blank" rel="noopener noreferrer">  Enginify</a> 
                        All rights reserved.&nbsp;</p>

                </div>
                <div class="social-buttons">
                    <ul class="social-buttons__list">
                        <li class="social-buttons__item">
                            <a class="social-buttons__link social-buttons__link--facebook" href="https://www.facebook.com/enginify" target="_blank" rel="noopener noreferrer" >
                            <img loading="lazy" class="social-buttons__icon" alt="facebook icon" src="https://dvzvtsvyecfyp.cloudfront.net/static/img/icons/social/white/facebook.svg" /></a>
                        </li>
                        <li class="social-buttons__item">
                                <a class="social-buttons__link social-buttons__link--instagram" href="https://www.instagram.com/enginify/" target="_blank" rel="noopener noreferrer">
                                <img loading="lazy" class="social-buttons__icon" alt="instagram icon" src="https://dvzvtsvyecfyp.cloudfront.net/static/img/icons/social/white/instagram.svg"/></a>
                        </li>
                        <li class="social-buttons__item"><a class="social-buttons__link social-buttons__link--linkedin" href="https://www.linkedin.com/company/enginify" target="_blank" rel="noopener noreferrer">
                                <img loading="lazy" class="social-buttons__icon" alt="linkedin icon" src="https://dvzvtsvyecfyp.cloudfront.net/static/img/icons/social/white/linkedin.svg"/>
                                </a>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>

    );
}

export default Footer;
